<!-- 顶部导航 -->
<template>
	<div class="header-iconMenu">
		<i
			class="iconfont"
			:class="isSidebar ? 'icon-guandiao' : 'icon-gengduo1'"
			@click="clickSidebar"
		></i>
		<div class="header-sidebar" v-if="isSidebar">
			<div class="menu-bg">
				<div class="menu-left">
					<el-menu
						:default-active="mobileActiveIndex"
						@select="handleSelect"
						:unique-opened="true"
						text-color="#333333"
						active-text-color="#1D67E8"
						@open="handleMenuOpen"
					>
						<!-- <el-menu-item
							class="iconNav-bg-color"
							index="1"
							@click="navigateToPage('/home')"
							>首页</el-menu-item
						> -->
						<el-submenu index="2">
							<template slot="title">产品中心</template>
							<el-menu-item
								class="iconNav-bg-color"
								v-for="(i, index) in seriesList"
								:index="`2-${index}`"
								:key="index"
								@click="showProductList(i.productList)"
								>{{ i.seriesName }}</el-menu-item
							>
						</el-submenu>
						<el-submenu index="3">
							<template slot="title">解决方案</template>
							<el-menu-item
								class="iconNav-bg-color"
								v-for="(i, index) in solutionList"
								:index="`3-${index}`"
								:key="index"
								@click="showSolutionList(i.fieldList)"
							>
								{{ i.professionName }}</el-menu-item
							>
						</el-submenu>
						<el-submenu index="4">
							<template slot="title">关于我们</template>
							<el-menu-item
								class="iconNav-bg-color"
								v-for="(i, index) in mobileAboutUsList"
								:index="i.keyPath.toString()"
								:key="index"
								@click="navigateToPage(i.keyPath)"
								>{{ i.keyName }}</el-menu-item
							>
						</el-submenu>
					</el-menu>
				</div>
				<div class="menu-right" v-show="showMenuRight">
					<div v-if="showMobileProduct">
						<div class="product-type-list">
							<el-link
								:underline="false"
								v-for="(i, index) in currentMobileProductList"
								:key="index"
								@click="navigateToPage(`/product?id=${i.id}`)"
								>{{ i.name }}
								<el-tag v-if="i.mark">NEW</el-tag></el-link
							>
						</div>
					</div>
					<div v-if="showMobileSolution">
						<el-scrollbar
							style="height: 100vh"
							wrap-style="overflow-y:auto;"
						>
							<div
								v-for="(
									item, index_f
								) in currentMobileFieldList"
								class="item-type"
								:key="index_f"
							>
								<div class="item-type-title">
									{{ item.fieldName }}
								</div>
								<div class="item-type-list">
									<el-link
										:underline="false"
										v-for="(i, index) in item.planList"
										:key="index"
										@click="
											navigateToPage(
												`/solution?id=${i.id}`
											)
										"
										>{{ i.name }}
										<el-tag v-if="i.mark"
											>HOT</el-tag
										></el-link
									>
								</div>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { menuAboutUs } from '@/utils/mock.js'
export default {
	name: 'NewsInfo',
	data() {
		return {
			isSidebar: false,
			mobileAboutUsList: menuAboutUs,
			htmlWidth: '',
			mobileTerminal: false,
			mobileActiveIndex: '2-0',
			showMobileProduct: false,
			currentMobileProductList: [], //需要展示的系列产品
			showMobileSolution: false,
			currentMobileFieldList: [], //需要展示的领域方案

			showMenuRight: true,
		}
	},
	props: {},
	watch: {},
	created() {},
	mounted() {
		if ((this, this._isMobile())) {
			this.mobileTerminal = true
		} else {
			this.mobileTerminal = false
		}
		this.htmlWidth = document.querySelector('html').offsetWidth
		//默认展示产品中心第一个系列的产品列表
		let productList = this.seriesList[0].productList
		this.showProductList(productList)

		// window.addEventListener('scroll', this.clearSelect, true)
	},
	methods: {
		handleHomeImg() {
			if (this.isMobileEnd) {
				this.mobileActiveIndex = '2-0'
				this.isSidebar = false
			}
		},
		handleSelect(key) {
			this.mobileActiveIndex = key
		},
		clearSelect() {
			this.isSidebar = false
		},
		clickSidebar() {
			this.mobileActiveIndex = '2-0'
			this.isSidebar = !this.isSidebar
		},
		showProductList(productList) {
			this.showMobileProduct = true
			this.showMobileSolution = false
			this.currentMobileProductList = productList
		},
		showSolutionList(fieldList) {
			this.showMobileProduct = false
			this.showMobileSolution = true
			this.currentMobileFieldList = fieldList
		},
		handleMenuOpen(key) {
			this.showMenuRight = true
			if (key == 2) {
				this.mobileActiveIndex = '2-0'
				let productList = this.seriesList[0].productList
				this.showProductList(productList)
			} else if (key == 3) {
				this.mobileActiveIndex = '3-0'
				let fieldList = this.solutionList[0].fieldList
				this.showSolutionList(fieldList)
			} else if (key == 4) {
				this.showMenuRight = false
			}
		},
		navigateToPage(keyPath) {
			// 假设 keyPath 是一个路由路径
			this.clearSelect()
			this.$router.push(keyPath)
		},
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
		seriesList() {
			return this.$store.state.seriesList
		},
		solutionList() {
			return this.$store.state.solutionList
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.header-iconMenu {
	display: none;
	// position: relative;
	i {
		display: block;
		color: #333333;
		font-size: 0.22rem;
	}
	.header-sidebar {
		position: fixed;
		top: 0.6rem;
		right: 0;
		width: 30%;
		.menu-bg {
			min-height: 400px;
			height: 100vh;
			display: flex;
			background-color: #fff;
			.menu-left {
				width: 138px;
				background-color: #f6f9fb;
				::v-deep .el-menu {
					background-color: transparent;
					border-right-color: transparent;
					.el-submenu {
						.el-submenu__title {
							height: 44px;
							line-height: 44px;
							i {
								color: #333333;
							}
							&:hover {
								background-color: rgba(226, 239, 255, 0.7);
							}
						}
						.el-menu--inline {
							background-color: rgba(226, 239, 255, 0.7);
						}
						.el-menu-item {
							height: 44px;
							min-width: 50px;
							box-sizing: border-box;
							&:hover,
							&:focus {
								background-color: rgba(226, 239, 255, 0.7);
							}
						}
						.el-menu-item.is-active {
							position: relative; /* 使伪元素相对于元素定位 */
							padding-left: 16px; /* 保持原有的内边距 */
							background-color: #d9ecff;
							&::before {
								content: '';
								position: absolute;
								left: 0;
								top: 50%;
								transform: translateY(-50%);
								width: 4px;
								height: 100%; /* 可以根据需要调整高度 */
								background-color: #1d67e8;
							}
						}
					}
					.is-opened {
						background-color: rgba(226, 239, 255, 0.7);
					}
				}
			}
			.menu-right {
				width: calc(100% - 138px);
				.product-type-list {
					margin-left: 12px;
					.el-link {
						display: block;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						line-height: 22px;
						margin-bottom: 16px;
						.el-tag {
							width: 35px;
							height: 16px;
							line-height: 16px;
							background: rgba(29, 103, 232, 0.15);
							border-radius: 4px;
							padding: 0;
							text-align: center;
							margin-left: 4px;
						}
						&:nth-child(1) {
							margin-top: 12px;
						}
						&:hover {
							color: #1d67e8;
						}
					}
				}
				.item-type {
					padding: 0 12px;
					&:nth-child(1) {
						margin-top: 12px;
					}
					.item-type-title {
						font-weight: 400;
						font-size: 14px; //14px
						color: #1d67e8;
						line-height: 22px; //22px
						text-align: left;
						padding: 0 0 8px 0; //8px
						border-bottom: 1px solid rgba(204, 204, 204, 0.5);
						margin-bottom: 4px; //4px
					}
					.item-type-list {
						text-align: left;
						.el-link {
							display: block;
							margin-bottom: 16px;
							font-family: Source Han Sans, Source Han Sans;
							font-weight: 400;
							font-size: 14px;
							color: #333333;
							line-height: 22px;
							.el-tag {
								width: 35px;
								height: 16px;
								line-height: 16px;
								color: #fa5151;
								background-color: rgba(250, 81, 81, 0.2);
								border-color: transparent;
								border-radius: 4px;
								padding: 0;
								text-align: center;
								margin-left: 4px;
							}
							&:nth-child(1) {
								margin-top: 12px;
							}
							&:hover {
								color: #1d67e8;
							}
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 768px) {
	.header-wrap {
		height: 44px;
		.header-iconMenu {
			i {
				font-size: 14px;
			}
			.header-sidebar {
				width: 100%;
				top: 44px;
				.sidebar {
					::v-deep .el-menu-item,
					.el-submenu__title {
						font-size: 14px;
					}
				}
			}
		}
	}
}
</style>
