var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-wrap"},[(_vm.showProduct || _vm.showSolution)?_c('div',{staticClass:"shade"}):_vm._e(),_c('div',{staticClass:"header-logo",on:{"click":function($event){return _vm.handleCommand('/home')}}},[(_vm.isMobileEnd)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.headerMobileLogo),expression:"headerMobileLogo"}],key:_vm.headerMobileLogo,attrs:{"alt":"logo"}}):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.headerLogoBlank),expression:"headerLogoBlank"}],key:_vm.headerLogoBlank,attrs:{"alt":"logo"}})]),_c('div',{staticClass:"header-navMenu"},[_c('el-link',{staticClass:"header-navMenu-item",attrs:{"underline":false},on:{"click":function($event){return _vm.handleCommand('/home')}}},[_vm._v("首页")]),_c('div',{staticClass:"header-navMenu-item",on:{"mouseover":function($event){_vm.showProduct = true},"mouseout":function($event){_vm.showProduct = false}}},[_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProduct),expression:"showProduct"}],staticClass:"item-product"},[_c('div',{staticClass:"item-product-content"},_vm._l((_vm.seriesList),function(item,index_p){return _c('div',{key:index_p,staticClass:"item-type",class:`item${index_p + 1}`,style:({
							'grid-row': `span ${
								item.productList.length >= 3
									? item.productList.length + 1
									: 4
							}`,
						})},[_c('div',{staticClass:"item-type-title"},[_c('div',{staticClass:"title-img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.seriesIcon),expression:"item.seriesIcon"}],attrs:{"alt":""}})]),_c('div',{staticClass:"title-name"},[_vm._v(" "+_vm._s(item.seriesName)+" ")])]),_c('div',{staticClass:"item-type-list"},_vm._l((item.productList),function(i,index){return _c('el-link',{key:index,attrs:{"underline":false},on:{"click":function($event){return _vm.handleCommand(`/product?id=${i.id}`)}}},[_vm._v(_vm._s(i.name)),(i.mark)?_c('el-tag',[_vm._v("NEW")]):_vm._e()],1)}),1)])}),0)])]),_c('div',{staticClass:"header-navMenu-item",on:{"mouseover":function($event){_vm.showSolution = true},"mouseout":function($event){_vm.showSolution = false}}},[_vm._m(1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSolution),expression:"showSolution"}],staticClass:"item-solution"},[_c('div',{staticClass:"item-solution-content"},[_c('div',{staticClass:"menu-last"},[_c('el-menu',{attrs:{"default-active":_vm.activeIndex},on:{"select":_vm.solutionSelect}},_vm._l((_vm.solutionList),function(item,index_p){return _c('el-menu-item',{key:index_p,attrs:{"index":index_p.toString()}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(item.professionName)+" ")])])}),1)],1),_c('div',{staticClass:"menu-right"},_vm._l((_vm.currentFieldList),function(item,index_f){return _c('div',{key:index_f,staticClass:"item-type",class:`item${index_f + 1}`,style:({
								'grid-row': `span ${
									item.planList.length >= 5
										? item.planList.length + 1
										: 5
								}`,
							})},[_c('div',{staticClass:"item-type-title"},[_vm._v(" "+_vm._s(item.fieldName)+" ")]),_c('div',{staticClass:"item-type-list"},_vm._l((item.planList),function(i,index){return _c('el-link',{key:index,attrs:{"underline":false},on:{"click":function($event){return _vm.handleCommand(
											`/solution?id=${i.id}`
										)}}},[_vm._v(_vm._s(i.name)+" "),(i.mark)?_c('el-tag',[_vm._v("HOT")]):_vm._e()],1)}),1)])}),0)])])]),_c('el-link',{staticClass:"header-navMenu-item",attrs:{"underline":false},on:{"click":function($event){return _vm.handleClickOpen(_vm.openPlatformUrl, '_blank')}}},[_vm._v("开放平台")]),_c('div',{staticClass:"header-navMenu-item",on:{"mouseover":function($event){_vm.showAbout = true},"mouseout":function($event){_vm.showAbout = false}}},[_vm._m(2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbout),expression:"showAbout"}],staticClass:"item-about-content"},_vm._l((_vm.AboutUsList),function(i,index){return _c('div',{key:index,staticClass:"item-type",on:{"click":function($event){return _vm.handleCommand(i.keyPath)}}},[_c('el-link',{attrs:{"underline":false}},[_vm._v(_vm._s(i.keyName))])],1)}),0)])],1),(_vm.isMobileEnd)?_c('div',{staticClass:"header-iconMenu"},[_c('MobileTopNav',{ref:"mobileTopNavRef"})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"item-title"},[_vm._v(" 产品中心"),_c('i',{staticClass:"iconfont icon-xiala-shang shang"}),_c('i',{staticClass:"iconfont icon-xiala-xia xia"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"item-title"},[_vm._v(" 解决方案"),_c('i',{staticClass:"iconfont icon-xiala-shang shang"}),_c('i',{staticClass:"iconfont icon-xiala-xia xia"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"item-title"},[_vm._v(" 关于我们"),_c('i',{staticClass:"iconfont icon-xiala-shang shang"}),_c('i',{staticClass:"iconfont icon-xiala-xia xia"})])
}]

export { render, staticRenderFns }