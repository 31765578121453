//导航-行业解决方案
export const menuSolution = [
	{
		id: '2-1',
		keyName: '智慧警务解决方案',
		keyPath: '/intelligentPolice',
	},
	{ id: '2-2', keyName: '智慧检务解决方案', keyPath: '/examine' },
	{ id: '2-3', keyName: '智慧法院解决方案', keyPath: '/court' },
	{ id: '2-4', keyName: '智慧监察解决方案', keyPath: '/monitor' },
	{ id: '2-5', keyName: '智慧案卷解决方案', keyPath: '/records' },
	{ id: '2-6', keyName: '智能车联解决方案', keyPath: '/carAi' },
	{
		id: '2-7',
		keyName: '智慧教育解决方案',
		keyPath: '/education',
	},
	{
		id: '2-8',
		keyName: '智慧财务解决方案',
		keyPath: '/imageOcr',
	},
	{
		id: '2-9',
		keyName: 'AI+政府解决方案',
		keyPath: '/administration',
	},
	{
		id: '2-10',
		keyName: '智能质检解决方案',
		keyPath: '/qualityInspectionPlan',
	},
]
export const productAllList = [
	{
		title: '慧听',
		svg: '',
		list: [
			{
				productId: '1',
				productName: '慧听智能语音会议系统',
			},
			{
				productId: '2',
				productName: '慧听便携式会议一体机',
			},
			{
				productId: '3',
				productName: '慧听智能语音审讯系统',
			},
			{
				productId: '4',
				productName: '慧听便携式审讯一体机',
			},
			{
				productId: '5',
				productName: '慧听智能语音庭审系统',
			},
			{
				productId: '6',
				productName: '慧听异常声音智能分析系统',
			},
			{
				productId: '7',
				productName: '慧听智能质检系统',
			},
			{
				productId: '8',
				productName: '慧听智能音频转录系统',
			},
			{
				productId: '9',
				productName: '慧听语音助手',
			},
		],
	},
	{
		title: '慧脑',
		svg: '',
		list: [
			{
				productId: '14',
				productName: '慧脑智能客服系统',
				subscript: true,
			},
			{
				productId: '15',
				productName: '慧脑云平台',
			},
		],
	},
	{
		title: '慧看',
		svg: '',
		list: [
			{
				productId: '16',
				productName: '慧看票据识别系统',
			},
			{
				productId: '17',
				productName: '慧看智能文件识别系统',
			},
		],
	},
	{
		title: '慧影',
		svg: '',
		list: [
			{
				productId: '18',
				productName: '慧影智能卷宗系统',
			},
		],
	},
	{
		title: '慧芯',
		svg: '',
		list: [
			{
				productId: '19',
				productName: '慧芯智盒系统',
			},
		],
	},
]
//导航-产品中心
export const menuProduct = [
	{ id: '3-1', productName: '慧听智能语音审讯系统' },
	{
		id: '3-2',
		productName: '慧听智能语音庭审系统',
	},
	{
		id: '3-3',
		productName: '慧听智能语音助手',
	},
	{
		id: '3-4',
		productName: '慧听智能语音会议系统',
	},
	{ id: '3-5', productName: '慧影智能卷宗' },
	{
		id: '3-6',
		productName: '慧思智能客服系统',
	},
	{
		id: '3-7',
		productName: '慧看票据识别系统',
	},
	{
		id: '3-8',
		productName: '慧看智能文字识别系统',
	},
	{
		id: '3-9',
		productName: '慧听异常声音分析系统',
	},
	{
		id: '3-10',
		productName: '慧听便携式会议一体机',
	},
	{
		id: '3-11',
		productName: '慧听便携式审讯一体机',
	},
	{
		id: '3-12',
		productName: '慧听智能质检系统',
	},
]
//导航-关于我们-跳转链接
export const menuAboutUs = [
	{ id: '5-1', keyName: '公司介绍', keyPath: '/companyOf' },
	{ id: '5-2', keyName: '新闻资讯', keyPath: '/newsAndTrends' },
	{ id: '5-3', keyName: '联系我们', keyPath: '/companyOf?id=boxContent' },
]
